import $ from 'jquery'
import './main.scss'

export class SectionManager {
  constructor(sectionClasses) {
    // Construct a mapping of section classes.
    this._loadSection = this._loadSection.bind(this)
    this._unloadSection = this._unloadSection.bind(this)
    this._selectSection = this._selectSection.bind(this)
    this._deselectSection = this._deselectSection.bind(this)
    this._selectBlock = this._selectBlock.bind(this)
    this._deselectBlock = this._deselectBlock.bind(this)
    this._sectionClasses = {}

    for (let sectionClass of Array.from(sectionClasses)) {
      if (sectionClass.type) {
        this._sectionClasses[sectionClass.type] = sectionClass
      }
    }

    // Initialize a mapping of sections.
    this._sections = {}

    // Initialize any session existing once thet DOM is ready.
    const manager = this

    $(function () {
      return $(
        '.simplero-section[id^=simplero-section-][data-simplero-section-type]'
      ).each(function () {
        return manager._loadSection(this)
      })
    })

    // Hook Simplero editor events.
    $(document).on('simplero:section:load', (e) =>
      manager._loadSection(e.target)
    )
    $(document).on('simplero:section:unload', (e) =>
      manager._unloadSection(e.target)
    )
    $(document).on('simplero:section:select', (e) =>
      manager._selectSection(e.target)
    )
    $(document).on('simplero:section:deselect', (e) =>
      manager._deselectSection(e.target)
    )
    $(document).on('simplero:block:select', (e) =>
      manager._selectBlock(e.target)
    )
    $(document).on('simplero:block:deselect', (e) =>
      manager._deselectBlock(e.target)
    )
  }

  _loadSection(sectionElem) {
    const $sectionElem = $(sectionElem)
    const sectionType = $sectionElem.attr('data-simplero-section-type')
    const sectionClass = this._sectionClasses[sectionType]

    initializeDynamic($sectionElem)

    if (sectionClass) {
      const section = new sectionClass($sectionElem)

      if (section.onLoad) {
        section.onLoad()
      }

      return (this._sections[$sectionElem.attr('id')] = section)
    }
  }

  _unloadSection(sectionElem) {
    let section
    const $sectionElem = $(sectionElem)

    if ((section = this._sections[$sectionElem.attr('id')])) {
      if (section.onUnload) {
        section.onUnload()
      }

      return delete this._sections[$sectionElem.attr('id')]
    }
  }

  _selectSection(sectionElem) {
    let section
    const $sectionElem = $(sectionElem)

    if ((section = this._sections[$sectionElem.attr('id')])) {
      if (section.onSelect) {
        return section.onSelect()
      }
    }
  }

  _deselectSection(sectionElem) {
    let section
    const $sectionElem = $(sectionElem)

    if ((section = this._sections[$sectionElem.attr('id')])) {
      if (section.onDeselect) {
        return section.onDeselect()
      }
    }
  }

  _selectBlock(blockElem) {
    let section
    const $blockElem = $(blockElem)
    const $sectionElem = $blockElem.closest('.simplero-section')

    if ((section = this._sections[$sectionElem.attr('id')])) {
      if (section.onBlockSelect) {
        return section.onBlockSelect($blockElem)
      }
    }
  }

  _deselectBlock(blockElem) {
    let section
    const $blockElem = $(blockElem)
    const $sectionElem = $blockElem.closest('.simplero-section')

    if ((section = this._sections[$sectionElem.attr('id')])) {
      if (section.onBlockDeselect) {
        return section.onBlockDeselect($blockElem)
      }
    }
  }
}

//#
// Wrap an iframe.
//
// Wraps an iframe width a known width and height in a wrapper, which provides
// responsive resizing.
//
// @param {Node} elem - Iframe DOM node.
const wrapIframe = function (elem) {
  let match
  const $elem = $(elem)

  // Bail on setup if the iframe is already wrapped.
  if ($elem.closest('.iframe-wrapper').length) {
    return
  }

  // Bail on setup if the iframe is in a WYSIWYG area being edited.
  if (
    $elem.closest('.wysiwyg-content[data-simplero-setting-presentation=inline]')
      .length
  ) {
    return
  }

  // Determine the width and height of the iframe.
  let width = elem.style.width || $elem.attr('width') || elem.style.maxWidth
  let height = elem.style.height || $elem.attr('height') || elem.style.maxHeight

  width = width != null && width.toString ? width.toString() : null
  height = height != null && height.toString ? height.toString() : null

  if (width != null && (match = width.match(/^(\d+)px$/))) {
    width = match[1]
  }

  if (height != null && (match = height.match(/^(\d+)px$/))) {
    height = match[1]
  }

  if (
    width == null ||
    height == null ||
    !width.match(/^\d+$/) ||
    !height.match(/^\d+$/)
  ) {
    return
  }

  width = parseInt(width, 10)
  height = parseInt(height, 10)

  // Wrap the iframe in a sizing wrapper to make iframes resize responsively.
  const $wrapper = $(
    '<div class="iframe-wrapper"><div class="iframe-wrapper__inner"></div></div>'
  )
  $wrapper.css({
    width: `${width}px`,
  })

  const $wrapperInner = $wrapper.find('.iframe-wrapper__inner')
  $wrapperInner.css({
    paddingBottom: `${(100 * height) / width}%`,
  })

  return $elem.wrap($wrapper)
}

//#
// Initialize dynamic content for a selector.
//
// Initializes iframe embeds.
//
// @param $selector - jQuery selector for the root from which to initialize
// the content.
const initializeDynamic = ($selector) =>
  $selector.find('iframe').each(function () {
    return wrapIframe(this)
  })
