// Base class for all sections. Can elect to respond to the following methods:
//
// - onLoad()
// - onUnload()
// - onSelect()
// - onDeselect()
// - onBlockSelect($blockElem)
// - onBlockDeselect($blockElem)
export class Section {
  constructor($elem) {
    this.elem = $elem
  }
}
